@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family:"Poppins", sans-serif;
  box-sizing: border-box;
}

body {
  background: #e3f9d4;
}
img {
  max-width: 100%;
}
.weather-card {
  width: 90%;
  max-width: 475px;
  text-align: center;
  padding: 35px 30px;
  margin: 30px auto 0;
  border-radius: 25px;
  color: #fff;
  background: linear-gradient(120deg, #00feba, #5b548a);

}
.weather-card .search input {
  width: 100%;
  height: 50px;
  font-size: 18px;
  flex: 1;
  color: rgb(44, 46, 46);
  padding: 10px 30px;
  outline: none;
  background: #bec6c5;
  border: none;
  border-radius: 30px;
}




.weather-card .search button  {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-left: 16px;
  border: none;
  outline: 0;
  background: #c3cfcd;
  border-radius: 50%;

}
.weather-card .search button i {
  font-size: 25px;
  color: #333;
}
.weather h1{
  font-size: 80px;
  font-weight: 400;
  margin-top: -30px;
}
.weather h2{
  font-size: 40px;
  font-weight: 400;
  margin-top: -25px;
}
.weather .weather-icon{
  width: 60;
  height:250px ;
  padding:10px ;
}
.details {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  align-items: center;
  padding:  0 20px;
}
.details .humi {
  filter: brightness(10000%);
}
.details img {
  width: 50px;
  height: 50px;
}
.details .humidity,
.wind {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: -5px;
  
}

.col {
  display: flex;
  justify-content: space-between ;
  align-items: center;
  text-align: left;
  margin-top: -20px;
}